<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="关联商品列表"
        sub-title=""
        @back="() => $router.go(-1)"
      >
        <a-row type="flex">
          <a-statistic title="" :value="'货物名：' + mdl.name" />
          <a-statistic
            title=""
            :value="'编码：' + mdl.code"
            :style="{
              margin: '0 32px',
            }"
          />
          <a-statistic title="" :value="'海关编码：' + mdl.hs_code" />
        </a-row>
      </a-page-header>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col :md="4" :sm="24">
              <a-form-item label="批次">
                <a-input v-model="queryParam.batch" placeholder="请输入批次" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24" v-if="false">
              <a-form-item label="报关单号">
                <a-input v-model="queryParam.customs_no" placeholder="请输入报关单号" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="账册编号">
                <a-input v-model="queryParam.ems_no" placeholder="请输入账册编号" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-form-item label="运输方式" >
                <a-select
                  v-model="queryParam.traf_mode"
                  placeholder="选择"
                  allow-clear
                >
                  <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.trans_mode">{{ val }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>

      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
        :scroll="{ x: 1500}"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleIn(record)">采购</a>
            <a-divider type="vertical"/>
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelet(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
      <create-form
        ref="createModal"
        v-if="visible"
        :visible="visible"
        :loading="confirmLoading"
        :model="mdl"
        @cancel="handleAddCancel"
        @ok="handleOk"
      />
      <commodity-purchase-form
        ref="createCommodityPurchaseModal"
        :visible="purchase_form_visible"
        :loading="confirmLoading"
        :model="purchase_form_mdl"
        @cancel="handleInCancel"
        @ok="handleInOk" />
    </div>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '@/views/c-wms/commodity/modules/CreateForm'
import { commodity_create, commodity_delete, commodity_list } from '@/api/c_wms_commodity'
import { Base64 } from 'js-base64'
import CommodityPurchaseForm from '@/views/c-wms/commodity_purchase/modules/CommodityPurchaseForm'
import { commodity_purchase_create } from '@/api/c_wms_commodity_purchase'

export default {
  name: 'WmsCommodityByGoodsList',
  components: {
    STable,
    CreateForm,
    CommodityPurchaseForm
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      purchase_form_visible: false,
      purchase_form_mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      myrecord: {},
      myrecord2: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          fixed: 'left',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '货品',
          width: 150,
          fixed: 'left',
          dataIndex: 'wms_goods',
          ellipsis: true
        },
        {
          title: '批次',
          width: 160,
          dataIndex: 'batch',
          ellipsis: true
        },
        {
          width: 150,
          title: '账册编号',
          dataIndex: 'ems_no',
          ellipsis: true
        },
        {
          width: 180,
          title: '商品编码',
          dataIndex: 'gcode',
          ellipsis: true
        },
        {
          width: 120,
          title: '企业商品编号',
          dataIndex: 'item_no',
          ellipsis: true
        },
        {
          width: 160,
          title: '账册备案料号',
          dataIndex: 'item_record_no',
          ellipsis: true
        },
        {
          width: 100,
          title: '运输方式',
          dataIndex: 'traf_mode',
          customRender: (text) => this.$Dictionaries.trans_mode[text] || '无'
        },
        {
          width: 150,
          title: '品牌、规格、型号',
          dataIndex: 'model',
          ellipsis: true
        },
        {
          title: '售卖单位',
          width: 100,
          dataIndex: 'unit',
          customRender: (text) => this.$Dictionaries.unit[text] || '无'
        },
        {
          title: '法定单位',
          width: 100,
          dataIndex: 'legal_unit',
          customRender: (text) => this.$Dictionaries.unit[text] || '无'
        },
        {
          title: '售卖单位与法定单位的比例',
          width: 200,
          align: 'center',
          dataIndex: 'rate'
        },
        {
          title: '每单位净重（千克）',
          width: 160,
          dataIndex: 'net_weight'
        },
        {
          title: '每单位毛重（千克）',
          width: 160,
          dataIndex: 'gross_weight'
        },
        {
          title: '总量',
          width: 100,
          dataIndex: 'total_count'
        },
        {
          title: '实际入库数量',
          width: 100,
          dataIndex: 'inbound_count'
        },
        {
          title: '是否已入库',
          width: 100,
          dataIndex: 'is_complete',
          customRender: (text) => text === true ? '是' : '否'
        },
        {
          title: '开启保质期',
          width: 100,
          dataIndex: 'open_self_life',
          customRender: (text) => text === true ? '是' : '否'
        },
        {
          title: '保质期开始时间',
          width: 120,
          dataIndex: 'self_life_begin',
          customRender: (text) => text === '1970-01-01' ? '无' : text
        },
        {
          title: '保质期结束时间',
          width: 120,
          dataIndex: 'self_life_end',
          customRender: (text) => text === '1970-01-01' ? '无' : text
        },
        {
          title: '条形码',
          width: 160,
          align: 'center',
          ellipsis: true,
          dataIndex: 'barcode'
        },
        {
          title: '备注',
          width: 140,
          ellipsis: true,
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return commodity_list(Object.assign(parameter, this.queryParam, { wms_goods_id: this.$route.params.id }))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.mdl = JSON.parse(Base64.decode(this.$route.query.record || ''))
    this.myrecord = JSON.parse(Base64.decode(this.$route.query.record || ''))
    this.myrecord2 = JSON.parse(Base64.decode(this.$route.query.record || ''))
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      this.mdl = this.myrecord
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleIn (record) {
      // this.purchase_form_visible = true
      // this.purchase_form_mdl = record
      this.$router.push({ path: '/zb/wms/commodity_purchase/goods/' + record.id, query: { record: Base64.encode(JSON.stringify(record)) } })
    },
    handleUpdate (record) {
      console.log(record)
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      console.log(record.id)
      commodity_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleInCancel () {
      this.purchase_form_visible = false
      const form = this.$refs.createCommodityPurchaseModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleInOk () {
      const form = this.$refs.createCommodityPurchaseModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.count = values.inbound_count
          values.wms_commodity_id = values.id
          commodity_purchase_create(values).then(res => {
            this.purchase_form_visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.wms_goods_id = this.$route.params.id
          commodity_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
